import * as React from 'react'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import Helmet from 'react-helmet'
import axios from 'axios'

function ContactPage() {
  const [serverState, setServerState] = React.useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: 'post',
      url: 'https://getform.io/f/41afe318-890e-48d3-a213-d82e0b709cc2',
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, 'Thanks!', form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }
  return (
    <Layout>
      <Helmet title="Laurie on Tech | Contact Form" />

      <Hero title="CONTACT ME" />
      <section id="about" className="wrapper container style4 post">
        Thanks for your interest in getting in touch with me! I'm currently
        pretty booked but I'm open to requests for:
        <br />
        <ul>
          <li>Podcast appearances</li>
          <li>Paid writing content</li>
          <li>Paid speaking engagements</li>
        </ul>
        I am not currently interested in job opportunities or other contract
        work. If you'd still like to get in touch, please use the form below.
        <br />
        <br />
        <form
          //   method="post"
          onSubmit={handleOnSubmit}
          //   action="https://getform.io/f/41afe318-890e-48d3-a213-d82e0b709cc2"
          className="contact"
          id="contact"
        >
          <label>
            Email
            <input type="email" name="email" />
          </label>
          <label>
            Name
            <input type="text" name="name" />
          </label>
          <label>
            Message
            <textarea type="textarea" name="message" form="contact" />
          </label>
          <button type="submit" disabled={serverState.submitting}>
            Submit
          </button>
          {serverState.status && (
            <p className={!serverState.status.ok ? 'errorMsg' : 'thanks'}>
              {serverState.status.msg}
            </p>
          )}
        </form>
      </section>
    </Layout>
  )
}

export default ContactPage
